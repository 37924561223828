import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import alert from '../assets/erro.png';
import olhoAberto from '../assets/ico-olhoaberto.png';
import olhoFechado from '../assets/ico-olhofechado.png';
import InputMaskDemo from '../components/InputMaskDemo';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import { VALIDA_SENHA_REGEX } from '../utils/constants';

export const Cadastro = () => {
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [olho1, setOlho1] = useState({
    src: olhoFechado,
    type: 'password',
  });
  const [olho2, setOlho2] = useState({
    src: olhoFechado,
    type: 'password',
  });
  const [erro, setErro] = useState(false);
  const [erro2, setErro2] = useState(false);
  const [erro3, setErro3] = useState(false);
  const [erro4, setErro4] = useState(false);
  const [erro5, setErro5] = useState(false);
  const [erro6, setErro6] = useState(false);

  async function handleSenha(campo) {
    if (campo === 1) {
      if (olho1.src === olhoAberto) {
        setOlho1({
          src: olhoFechado,
          type: 'password',
        });
      } else {
        setOlho1({
          src: olhoAberto,
          type: 'text',
        });
      }
    } else if (campo === 2) {
      if (olho2.src === olhoAberto) {
        setOlho2({
          src: olhoFechado,
          type: 'password',
        });
      } else {
        setOlho2({
          src: olhoAberto,
          type: 'text',
        });
      }
    }
  }

  async function gravarDados1() {
    localStorage.setItem('cpf', cpf);
    localStorage.setItem('nome', nome);
    localStorage.setItem('password', password);
    localStorage.setItem('confirmpassword', confirmpassword);

    let cpfExiste = localStorage
      .getItem('cpf')
      .replace('.', '')
      .replace('.', '')
      .replace('-', '');

    let urlGet1 = `${process.env.REACT_APP_API_AUTH}/Auth/check-user-cpf?cpf=${cpfExiste}`;

    axios.get(urlGet1).then(res => {
      if (res.data.existe === true) {
        return setErro6(true);
      } else {
        setErro(false);
        setErro2(false);
        setErro3(false);
        setErro4(false);

        if (!password || !confirmpassword) {
          return setErro3(true);
        }

        if (!VALIDA_SENHA_REGEX.test(password)) {
          return setErro4(true);
        }

        if (!VALIDA_SENHA_REGEX.test(confirmpassword)) {
          return setErro4(true);
        }

        if (password !== confirmpassword) {
          return setErro(true);
        }

        if (
          erro == true ||
          erro2 == true ||
          erro3 == true ||
          erro4 == true ||
          erro5 == true
        ) {
          return false;
        } else {
          window.location.href = '#/cadastrov2';
        }
      }
    });
  }

  async function logar() {
    localStorage.clear();
    window.location.href = '#/identidade';
  }

  const [dadospessoais, setDadospessoais] = useState({});

  function validaCpfCnpj(val) {
    setErro5(false);
    const qtdNumeros = val
      .split('')
      .filter(n => Number(n) || n == 0)
      .join('');

    if (qtdNumeros.length === 11) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: '' });
        return setErro5(true);
      }

      for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = (v1 * 10) % 11;

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: '' });
        return setErro5(true);
      }

      for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = (v2 * 10) % 11;

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: '' });
        return setErro5(true);
      } else {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: val });
        return true;
      }
    } else if (val.length == 18) {
      var cnpj = val.trim();

      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', '');
      cnpj = cnpj.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] != cnpj[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return setErro5(true);
      }

      for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = v1 % 11;

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = 11 - v1;
      }

      if (v1 != cnpj[12]) {
        return setErro5(true);
      }

      for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = v2 % 11;

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = 11 - v2;
      }

      if (v2 != cnpj[13]) {
        return setErro5(true);
      } else {
        return true;
      }
    } else {
      return setErro5(true);
    }
  }

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              <h2>Para continuar</h2>
              <p className="f16">preencha os campos abaixo:</p>
              <InputMaskDemo
                id="cpf"
                mask="999.999.999-99"
                placeholder="Digite seu CPF"
                className={erro5 || erro6 ? 'user user_i' : 'user'}
                acao={e => {
                  setCpf(e.target.value);
                  setErro5(false);
                  setErro6(false);
                  validaCpfCnpj(e.target.value);
                }}
                valor={cpf}
                autoFocus
              ></InputMaskDemo>
              <div>
                <InputText
                  className={erro || erro3 || erro4 ? 'senha senha_i' : 'senha'}
                  name="password"
                  placeholder="Digite sua senha*"
                  type={olho1.type}
                  onChange={e => {
                    setPassword(e.target.value);
                    setErro(false);
                    setErro3(false);
                  }}
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSenha(1)}
                  onKeyDown={e => e.code === 'Space' && handleSenha(1)}
                >
                  <img src={olho1.src} alt="olho fechado" />
                </div>
              </div>
              <div>
                <InputText
                  className={erro || erro3 || erro4 ? 'senha senha_i' : 'senha'}
                  name="confirmpassword"
                  placeholder="Confirme sua senha"
                  type={olho2.type}
                  onChange={e => {
                    setConfirmpassword(e.target.value);
                    setErro(false);
                    setErro3(false);
                  }}
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSenha(2)}
                  onKeyDown={e => e.code === 'Space' && handleSenha(2)}
                >
                  <img src={olho2.src} alt="olho aberto" />
                </div>
              </div>
              <h6>* 8 dígitos alfanuméricos e caracteres especiais.</h6>
              {erro2 && (
                <small>
                  <img src={alert} alt="Erro" /> Digite seu nome completo
                </small>
              )}
              {erro3 && (
                <small>
                  <img src={alert} alt="Erro" /> Digite as senhas
                </small>
              )}
              {erro4 && (
                <small>
                  <img src={alert} alt="Erro" /> Insira 8 dígitos alfanuméricos{' '}
                  <br />
                  com caracteres especiais.
                </small>
              )}
              {erro5 && (
                <small>
                  <img src={alert} alt="Erro" /> CPF inválido. <br />
                </small>
              )}
              {erro6 && (
                <small
                  id="erro6"
                  role="button"
                  tabIndex={0}
                  onClick={logar}
                  onKeyDown={e => e.code === 'Enter' && logar()}
                >
                  <img src={alert} alt="Erro" />
                  <p>CPF já cadastrado. Clique aqui para logar.</p>
                  <br />
                </small>
              )}
              {erro && (
                <small>
                  <img src={alert} alt="Erro" /> As senhas não conferem
                </small>
              )}
              <Button
                label="Avançar"
                type="submit"
                onClick={() => gravarDados1()}
              ></Button>

              <br />
              <p>
                Ao clicar em Avançar, você concorda com os{' '}
                <a
                  href="https://www.bussoladoinvestidor.com.br/termos-de-uso"
                  target="_blank"
                  rel="noreferrer"
                >
                  termos de uso
                </a>{' '}
                e a{' '}
                <a
                  href="https://www.bussoladoinvestidor.com.br/privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  política de privacidade.
                </a>
              </p>
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
          <br />
        </div>
      </div>
    </div>
  );
};
