export function validaCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split('').map(el => +el);
  const rest = count =>
    ((cpf
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;
  return rest(10) === cpf[9] && rest(11) === cpf[10];
}

export function validaCelular(celular) {
  if (!celular) return false;

  const numeros = celular.replace(/\D+/g, '');

  if (
    numeros === '00000000000' ||
    numeros === '11111111111' ||
    numeros === '22222222222' ||
    numeros === '33333333333' ||
    numeros === '44444444444' ||
    numeros === '55555555555' ||
    numeros === '66666666666' ||
    numeros === '77777777777' ||
    numeros === '88888888888' ||
    numeros === '99999999999'
  ) {
    return false;
  }

  if (numeros.length < 11) return false;

  return true;
}

export function validaNascimento(data) {
  const dataRegex =
    /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/i;

  if (!dataRegex.test(data)) return false;

  const ano = parseInt(data.split('/')[2]);

  if (ano <= 1904) return false;

  const hoje = new Date().toISOString();
  const dataISO = new Date(data.split('/').reverse().join('-')).toISOString();

  if (hoje < dataISO) return false;

  return true;
}
