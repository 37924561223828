import { Button } from 'primereact/button';

import Logo from '../../components/Logo';
import PrecisaDeAjuda from '../../components/PrecisaDeAjuda';
import { STATUS_CONFIG } from './constants';

const capitalize = s => s.replace(/./, c => c.toUpperCase());

export const Status = ({ tipo = 'migracaoIniciada', usuario = 'Usuário' }) => {
  const { titulo, mensagem, buttonLabel, acao } = STATUS_CONFIG[tipo];

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />
            <div className="status-wrapper">
              <div className="login-form">
                <h2>
                  {capitalize(usuario)}, {titulo}
                </h2>
                <p>{mensagem}</p>
                <Button label={buttonLabel} onClick={acao} />
              </div>
            </div>
          </div>

          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
        </div>
      </div>
    </div>
  );
};
