import { Toast } from 'primereact/toast';
import { ReactNode, useContext, useRef } from 'react';
import { createContext } from 'react';

import { ERROR_KEY, getToastInfo } from './constants';
import { ToastInfo } from './types';

interface ToastContextData {
  showToast: (key?: string, setInfo?: ToastInfo) => void;
  currentToast: Toast | null;
}

interface ToastProviderProps {
  children: ReactNode;
}

const ToastContext = createContext({} as ToastContextData);

export function ToastProvider({ children }: ToastProviderProps) {
  const toastRef = useRef<Toast>(null);

  const showToast = (key = ERROR_KEY, setInfo = {} as ToastInfo) => {
    const { severity, summary, detail, life } = getToastInfo(key);

    const {
      severity: setSeverity,
      summary: setSummary,
      detail: setDetail,
      life: setLife,
    } = setInfo;

    toastRef.current?.show({
      severity: setSeverity || severity,
      summary: setSummary || summary,
      detail: setDetail || detail,
      life: setLife || life,
    });
  };

  return (
    <ToastContext.Provider
      value={{ showToast, currentToast: toastRef.current }}
    >
      {children}
      <Toast ref={toastRef} />
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext);
