import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '../../../service/api';
import apiUsuarios from '../../../service/apiUsuarios';
import { falha, loginSucesso } from './actions';

export function* login({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, '/Auth/login', {
      email,
      password,
    });

    const { payment, tokenh, tokenm, user } = response.data;

    const token = tokenh;

    const usuario = { user, payment };

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const respostaMigracao = yield call(
      apiUsuarios.get,
      `/Auth/v1/checkUsuarioMigrado/${user.id}`,
    );

    const temOperacoes = yield call(
      apiUsuarios.get,
      `/DadosPessoais/v1/CheckUserOperations/${user.id}`,
    );

    const {
      migrado,
      dadosAtualizados,
      dataInicioMigracao,
      dataFimMigracao,
      statusMigracao,
    } = respostaMigracao.data;
    const { existsOperations } = temOperacoes.data;

    let userLegado = {};
    if (migrado) {
      const dadosPessoaisLegado = yield call(
        apiUsuarios.get,
        `/DadosPessoais/v1/GetUsuarioDadosPessoaisLegado/${user.username}`,
      );
      userLegado = dadosPessoaisLegado.data;
    }

    let cookieOptions = {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: 1,
      priority: 'High',
      SameSite: process.env.REACT_APP_COOKIE_SAMESITE,
      Secure: process.env.REACT_APP_COOKIE_SAFE === 'true',
      path: '/',
    };

    Cookies.set('Id', user.id, cookieOptions);

    Cookies.set('username', user.username, cookieOptions);

    Cookies.set('cpf', user.cpf, cookieOptions);

    Cookies.set('pro', user.pro, cookieOptions);

    Cookies.set('tokenh', tokenh, cookieOptions);

    Cookies.set('tokenm', tokenm, cookieOptions);

    Cookies.set('datacadastro', user.data, cookieOptions);
    Cookies.set('cidade', user.cidade, cookieOptions);
    Cookies.set('estado', user.estado, cookieOptions);
    Cookies.set('pais', user.pais, cookieOptions);

    if (payment) Cookies.set('payment', payment, cookieOptions);
    if (payment) Cookies.set('paymentPlan', payment.paymentPlan, cookieOptions);

    Cookies.set('graficosTempoReal', user.graficosTempoReal, cookieOptions);

    const usuarioLogado = {
      id: user.id || userLegado.id,
      nome: user.name || userLegado.name,
      username: user.username || userLegado.username,
      telefone: user.telefone || userLegado.telefone,
      email: user.email || userLegado.email,
      cpf: user.cpf || userLegado.cpf,
      nascimento: user.nascimento,
      foto: user.foto || userLegado.foto,
      datacadastro: user.data,
      cidade: user.cidade || userLegado.cidade,
      estado: user.estado || userLegado.estado,
      pais: user.pais || userLegado.pais,
      pro: user.pro || userLegado.pro,
      payment: payment ? payment : '',
      dadosAtualizados,
      migrado,
      existsOperations,
      dataInicioMigracao,
      dataFimMigracao,
      statusMigracao,
    };

    Cookies.set('usuario', JSON.stringify(usuarioLogado), cookieOptions);

    yield put(loginSucesso(token, usuario));

    if (migrado && statusMigracao !== 'MIGRADO' && statusMigracao !== null) {
      window.location.href = '#/migracaoNaoFinalizada';
      return;
    }

    if (!dadosAtualizados) {
      window.location.href = '#/cadastrov2';
      return;
    }

    window.location.href = process.env.REACT_APP_URL_CALCULADORA;
  } catch (error) {
    const mensagem = error.response.data.value.mensagem;
    const numero_tentativas = error.response.data.value.numero_tentativas;
    yield put(falha(mensagem, numero_tentativas));
    return error.response;
  }
}

export function* cadastro({ payload }) {
  try {
    const { nome, email, password, passwordConfirm } = payload.nome;

    let data = {
      nome,
      email,
      username: email,
      password,
      passwordConfirm,
    };

    yield call(api.post, '/Auth/register', data);

    const response = yield call(api.post, '/Auth/login', {
      email,
      password,
    });

    const { payment, tokenh, tokenm, user } = response.data;

    const token = tokenh;

    const usuario = { user, payment };

    api.defaults.headers.Authorization = `Bearer ${token}`;

    let cookieOptions = {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: 1,
      priority: 'High',
      SameSite: process.env.REACT_APP_COOKIE_SAMESITE,
      Secure: process.env.REACT_APP_COOKIE_SAFE === 'true',
      path: '/',
    };

    Cookies.set('Id', user.id, cookieOptions);

    Cookies.set('username', user.username, cookieOptions);

    Cookies.set('cpf', user.cpf, cookieOptions);

    Cookies.set('datacadastro', user.data, cookieOptions);

    Cookies.set('cidade', user.cidade, cookieOptions);
    Cookies.set('estado', user.estado, cookieOptions);
    Cookies.set('pais', user.pais, cookieOptions);

    Cookies.set('pro', user.pro, cookieOptions);

    Cookies.set('tokenh', tokenh, cookieOptions);

    Cookies.set('tokenm', tokenm, cookieOptions);

    if (payment) Cookies.set('paymentPlan', payment.paymentPlan, cookieOptions);

    Cookies.set('graficosTempoReal', user.graficosTempoReal, cookieOptions);

    yield put(loginSucesso(token, usuario));

    window.location.href = process.env.REACT_APP_URL_CALCULADORA;
  } catch (error) {
    toast.error(`E-mail já cadastrado!`);

    yield put(falha());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function logout() {
  window.location.href = '#/identidade';
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', login),
  takeLatest('@auth/CADASTRO_REQUEST', cadastro),
  takeLatest('@auth/LOGOUT', logout),
]);
