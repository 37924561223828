import { useState } from 'react';

import Logo from '../../components/Logo';
import PrecisaDeAjuda from '../../components/PrecisaDeAjuda';
import api from '../../service/api';

export const CheckEmail = () => {
  const [naoRecebi, setNaoRecebi] = useState(false);

  const Id = localStorage.getItem('Id');
  const email = localStorage.getItem('identificacao');
  const Nome = localStorage.getItem('Nome');

  async function handleSubmit2() {
    const data = {
      id: Id,
      name: Nome,
      email: email,
    };

    await api.post('/Auth/forgot-password', data);

    setNaoRecebi(true);
  }

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              <svg
                width="134"
                height="134"
                viewBox="0 0 134 134"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M114.376 19.6237C101.721 6.96931 84.8963 0 67 0C49.1034 0 32.278 6.96931 19.6237 19.6237C6.96905 32.2783 0 49.1037 0 67C0 84.8963 6.96905 101.721 19.6237 114.376C32.2783 127.031 49.1034 134 67 134C84.8963 134 101.721 127.031 114.376 114.376C127.031 101.721 134 84.8963 134 67C134 49.1037 127.031 32.2786 114.376 19.6237ZM67 126.148C34.3854 126.148 7.85156 99.6146 7.85156 67C7.85156 34.3854 34.3854 7.85156 67 7.85156C99.6146 7.85156 126.148 34.3854 126.148 67C126.148 99.6146 99.6146 126.148 67 126.148Z"
                  fill="#53C3C2"
                />
                <path
                  d="M99.0095 45.5022C97.4766 43.9696 94.9908 43.9696 93.4579 45.5025L58.7909 80.1695L40.542 61.9206C39.0091 60.3877 36.5233 60.3877 34.9902 61.9206C33.457 63.4535 33.457 65.9393 34.9902 67.4725L56.0148 88.4971C56.7814 89.2637 57.7862 89.6468 58.7906 89.6468C59.7951 89.6468 60.8001 89.2634 61.5664 88.4971L99.0095 51.0541C100.543 49.5212 100.543 47.0354 99.0095 45.5022Z"
                  fill="#53C3C2"
                />
              </svg>

              <h2>Verifique seu e-mail</h2>

              {!naoRecebi && (
                <p className="f16">
                  Enviamos um e-mail com o link
                  <br />
                  de alteração de senha.
                </p>
              )}

              {naoRecebi && (
                <p className="f16">
                  Caso não encontre em sua caixa de
                  <br />
                  entrada, verifique sua pasta de Spam.
                </p>
              )}

              {naoRecebi && (
                <p className="f16light">
                  São permitidas até 5 tentativas
                  <br />
                  de reenvio de e-mail.
                </p>
              )}
            </div>
          </div>
          <small className="pLink">
            {naoRecebi && <PrecisaDeAjuda />}
            <div
              role="button"
              tabIndex={0}
              onClick={handleSubmit2}
              onKeyDown={e => e.code === 'Enter' && handleSubmit2()}
            >
              <a>Não recebi o e-mail</a>
            </div>
          </small>
        </div>
      </div>
    </div>
  );
};
