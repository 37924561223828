import { useRef } from 'react';

const AppCodeHighlight = props => {
  const codeElement = useRef(null);

  return (
    <pre style={props.style}>
      <code ref={codeElement} className={`language-${props.lang}`}>
        {props.children}&nbsp;
      </code>
    </pre>
  );
};

AppCodeHighlight.defaultProps = {
  lang: 'jsx',
  style: null,
};

export default AppCodeHighlight;
