import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

import alert from '../assets/erro.png';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import api from '../service/api';

const useSSNFields = () => {
  const [ssnValues, setValue] = useState({
    ssn1: '',
    ssn2: '',
    ssn3: '',
    ssn4: '',
    ssn5: '',
  });

  return {
    handleChange: e => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split('-');

      // Check if they hit the max character length
      if (value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 5) {
          // Get the next input field
          const nextSibling = document.querySelector(
            `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`,
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }

      setValue({
        ...value,
        [`ssn${fieldIndex}`]: value,
      });
    },
  };
};

export const Token = () => {
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const [t1, setT1] = useState();
  const [t2, setT2] = useState();
  const [t3, setT3] = useState();
  const [t4, setT4] = useState();
  const [t5, setT5] = useState();
  const [erro1, setErro1] = useState(false);
  const [erro2, setErro2] = useState(false);
  const [erro3, setErro3] = useState(false);
  const [reenvio, setReenvio] = useState(0);
  const [botaoAvancar, setBotaoAvancar] = useState(false);

  const { handleChange } = useSSNFields();

  async function handleSubmit() {
    setErro1(false);
    setErro2(false);

    if (!t1 || !t2 || !t3 || !t4 || !t5) {
      return setErro1(true);
    }

    const tok = t1 + t2 + t3 + t4 + t5;

    if (tok !== token) {
      return setErro2(true);
    }

    window.location.href = '#/cadastro';
  }

  useEffect(() => {
    async function Reenviar() {
      if (reenvio <= 5) {
        var now = new Date();
        now.setTime(now.getTime() + 1 * 3600 * 1000);

        Cookies.set('qtdReenvio', reenvio, {
          expires: now.setTime(now.getTime() + 1 * 3600 * 1000),
        });

        const user = await api.get(`/Auth/check-user?Email=${email}`);

        setT1('');
        setT2('');
        setT3('');
        setT4('');
        setT5('');
        setErro1(false);
        setErro2(false);

        const { token } = user.data;

        localStorage.setItem('token', token);

        setToken(localStorage.getItem('token'));
        setBotaoAvancar(false);
      } else {
        setBotaoAvancar(true);
        return setErro3(true);
      }
    }

    Reenviar();
  }, [reenvio]);

  useEffect(() => {
    async function loadToken() {
      setEmail(localStorage.getItem('identificacao'));
      setToken(localStorage.getItem('token'));
    }
    loadToken();
  }, [email, token]);

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              {!reenvio ? (
                <>
                  <h2>Digite o código</h2>
                  <p className="f16">enviado para o e-mail informado.</p>
                </>
              ) : (
                <>
                  <h2 className="h2reenvio">Verifique seu e-mail</h2>
                  <p>Digite o novo código informado.</p>
                </>
              )}
              <div className="token">
                <InputText
                  className={erro1 || erro2 ? 'invalido' : ''}
                  name="ssn-1"
                  value={t1}
                  maxLength="1"
                  onChange={e => {
                    setT1(e.target.value);
                    setErro1(false);
                    setErro2(false);
                  }}
                  autoFocus
                  onKeyUp={handleChange}
                />
                <InputText
                  className={erro1 || erro2 ? 'invalido' : ''}
                  name="ssn-2"
                  value={t2}
                  maxLength="1"
                  onChange={e => {
                    setT2(e.target.value);
                    setErro1(false);
                    setErro2(false);
                  }}
                  onKeyUp={handleChange}
                />
                <InputText
                  className={erro1 || erro2 ? 'invalido' : ''}
                  name="ssn-3"
                  value={t3}
                  maxLength="1"
                  onChange={e => {
                    setT3(e.target.value);
                    setErro1(false);
                    setErro2(false);
                  }}
                  onKeyUp={handleChange}
                />
                <InputText
                  className={erro1 || erro2 ? 'invalido' : ''}
                  name="ssn-4"
                  value={t4}
                  maxLength="1"
                  onChange={e => {
                    setT4(e.target.value);
                    setErro1(false);
                    setErro2(false);
                  }}
                  onKeyUp={handleChange}
                />
                <InputText
                  className={erro1 || erro2 ? 'invalido' : ''}
                  name="ssn-5"
                  value={t5}
                  maxLength="1"
                  onChange={e => {
                    setT5(e.target.value);
                    setErro1(false);
                    setErro2(false);
                  }}
                  onKeyUp={handleChange}
                />
              </div>
              {erro1 && (
                <small>
                  <img src={alert} alt="Erro" /> Preencha todos os campos
                </small>
              )}
              {erro2 && (
                <small>
                  <img src={alert} alt="Erro" /> Código incorreto
                </small>
              )}
              {erro3 && (
                <small>
                  <img src={alert} alt="Erro" /> Limite de tentativas atingido.
                  Tente em alguns minutos
                </small>
              )}
              <Button
                label="Avançar"
                type="submit"
                onClick={handleSubmit}
              ></Button>
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
        </div>
      </div>
    </div>
  );
};
