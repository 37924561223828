import Cookies from 'js-cookie';

import history from '../service/history';

export function Logouf() {
  let cookieOptions = {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    expires: 1,
    priority: 'High',
    SameSite: process.env.REACT_APP_COOKIE_SAMESITE,
    Secure: process.env.REACT_APP_COOKIE_SAFE === 'true',
    path: '/',
  };

  Cookies.remove('Id', cookieOptions);
  Cookies.remove('username', cookieOptions);
  Cookies.remove('cpf', cookieOptions);
  Cookies.remove('pro', cookieOptions);
  Cookies.remove('tokenh', cookieOptions);
  Cookies.remove('tokenm', cookieOptions);
  Cookies.remove('paymentPlan', cookieOptions);
  Cookies.remove('graficosTempoReal', cookieOptions);
  Cookies.remove('usuario', cookieOptions);
  history.push('#/identidade');
  return window.location.reload();
}
