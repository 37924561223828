export default function PrecisaDeAjuda() {
  return (
    <a
      href="https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br"
      target="_blank"
      rel="noreferrer"
    >
      Precisa de ajuda?
    </a>
  );
}
