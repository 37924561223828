import axios from 'axios';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import alert from '../assets/erro.png';
import InputMaskDemo from '../components/InputMaskDemo';
import InputTextDemo from '../components/InputTextDemo';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import apiCorreios from '../service/apiCorreios';
import { loginRequest } from '../store/modules/auth/actions';

export const Cadastrov3 = () => {
  const usuario = Cookies.get('usuario');

  let _dadosAtualizados = false;
  let _id = '';
  let _migrado = false;
  let _existsOperations = false;

  if (usuario) {
    const { dadosAtualizados, id, migrado, existsOperations } = JSON.parse(
      Cookies.get('usuario'),
    );

    _dadosAtualizados = dadosAtualizados;
    _id = id;
    _migrado = migrado;
    _existsOperations = existsOperations;
  }

  const [cep, setCep] = useState('');
  const toast = useRef(null);
  const dispatch = useDispatch();

  const [dadosEndereco, setDadosEndereco] = useState({});
  const [erro, setErro] = useState(false);
  const [desabilitaInputs, setDeabilitaInputs] = useState(true);

  async function consultaCEP() {
    const response = await apiCorreios.get(`${cep}/json`);

    if (response.data.erro !== 'true') {
      setDadosEndereco({
        ...dadosEndereco,
        rua: response.data.logradouro,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
      });
      setErro(false);
      setDeabilitaInputs(false);
    } else {
      setErro(true);
      setDeabilitaInputs(true);
    }
  }

  const postDadosPessoais = async id => {
    const url = `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/PostUsuarioDadosPessoais`;

    const payload = {
      idUsuario: id,
      nomeCompleto: localStorage.getItem('nomeCompleto'),
      cpf: localStorage.getItem('cpf'),
      dataNascimento: new Date(localStorage.getItem('dataInicioFormatada')),
      enderecoCep: cep,
      enderecoLogradouro: dadosEndereco.rua,
      enderecoNumero: dadosEndereco.numero,
      enderecoComplemento: dadosEndereco.complemento,
      enderecoCidade: dadosEndereco.cidade,
      enderecoEstado: dadosEndereco.estado,
      bairro: dadosEndereco.bairro,
      numeroCelular: localStorage.getItem('celular'),
      urlFotoPerfil: '',
      dataNascimentoFormatada: localStorage.getItem('dtnascimento'),
    };

    try {
      await axios.post(url, payload);

      if (_migrado && !_dadosAtualizados && !_existsOperations) {
        window.location.href = '#/cadastroAtualizado';
        return;
      }

      // if (_migrado && !_dadosAtualizados && _existsOperations) {
      //     window.location.href = "#/corretoras";
      //     return;
      // }

      if (
        localStorage.getItem('identificacao') &&
        localStorage.getItem('password')
      ) {
        dispatch(
          loginRequest(
            localStorage.getItem('identificacao'),
            localStorage.getItem('password'),
          ),
        );
      } else {
        window.location.href = '#/login';
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erro!',
        detail: 'Erro ao cadastrar.',
        life: 8000,
      });
    }
  };

  const register = async () => {
    const payload = {
      nome: localStorage.getItem('nomeCompleto'),
      email: localStorage.getItem('identificacao'),
      username: localStorage.getItem('identificacao'),
      password: localStorage.getItem('password'),
      PasswordConfirm: localStorage.getItem('confirmpassword'),
      cpf: localStorage.getItem('cpf'),
    };

    const url = `${process.env.REACT_APP_API_AUTH}/Auth/register`;

    const { data } = await axios.post(url, payload);

    return data.user.id;
  };

  async function gravarDados3() {
    if (!erro) {
      if (_id === 0 || _id === '' || _id === null) {
        const id = await register();
        await postDadosPessoais(id);

        return;
      }

      if (!_dadosAtualizados) {
        await postDadosPessoais(_id);
      }
    } else {
      return setErro(true);
    }
  }

  return (
    <div className="login-body">
      <Toast ref={toast} />
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />
            <div className="login-form cadastro3">
              <h2>Para finalizar, confirme seu endereço!</h2>
              <p className="f16">Após sua confirmação clique em ENTRAR</p>

              <InputMaskDemo
                name="cep"
                id="cep"
                mask="99999-999"
                placeholder="CEP"
                valor={cep}
                className={erro ? 'user user_i' : 'user'}
                acao={e => {
                  setCep(e.target.value);
                  setErro(false);
                }}
                acao2={() => consultaCEP()}
              ></InputMaskDemo>

              <InputTextDemo
                type="text"
                className="user"
                placeholder="Logradouro"
                name="rua"
                id="rua"
                valor={dadosEndereco.rua}
                acao={e =>
                  setDadosEndereco({
                    ...dadosEndereco,
                    rua: e.target.value,
                  })
                }
                disabled={desabilitaInputs}
              />
              <div>
                <InputTextDemo
                  type="number"
                  className="user"
                  placeholder="Número"
                  name="numero"
                  id="numero"
                  valor={dadosEndereco.numero}
                  acao={e =>
                    setDadosEndereco({
                      ...dadosEndereco,
                      numero: e.target.value,
                    })
                  }
                  disabled={desabilitaInputs}
                />

                <InputTextDemo
                  type="text"
                  className="user"
                  placeholder="Complemento"
                  id="complemento"
                  valor={dadosEndereco.complemento}
                  acao={e =>
                    setDadosEndereco({
                      ...dadosEndereco,
                      complemento: e.target.value,
                    })
                  }
                  disabled={desabilitaInputs}
                />
              </div>

              <InputTextDemo
                type="text"
                className="user"
                placeholder="Bairro"
                name="bairro"
                id="bairro"
                valor={dadosEndereco.bairro}
                acao={e =>
                  dadosEndereco({
                    ...dadosEndereco,
                    bairro: e.target.value,
                  })
                }
                disabled={desabilitaInputs}
              />

              <InputTextDemo
                type="text"
                className="user"
                placeholder="Cidade"
                name="cidade"
                id="cidade"
                valor={dadosEndereco.cidade}
                acao={e =>
                  dadosEndereco({
                    ...dadosEndereco,
                    cidade: e.target.value,
                  })
                }
                disabled={desabilitaInputs}
              />

              <InputTextDemo
                type="text"
                className="user"
                placeholder="Estado"
                name="uf"
                id="uf"
                valor={dadosEndereco.estado}
                acao={e =>
                  dadosEndereco({
                    ...dadosEndereco,
                    estado: e.target.value,
                  })
                }
                disabled={desabilitaInputs}
              />
              <br />
              {erro && (
                <small>
                  <img src={alert} alt="Erro" /> CEP inválido
                </small>
              )}
              <br />
              <Button
                label="Entrar"
                type="submit"
                onClick={() => gravarDados3()}
              ></Button>
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
          <br />
        </div>
      </div>
    </div>
  );
};
