export const STATUS_CONFIG = {
  migracaoIniciada: {
    titulo: 'sua migração foi iniciada',
    mensagem:
      'Não se preocupe, assim que terminar enviaremos um e-mail para você.',
    buttonLabel: 'OK',
    acao: () => (window.location.href = '#/identidade'),
  },
  migracaoNaoFinalizada: {
    titulo: 'sua migração  ainda não foi finalizada',
    mensagem: 'Assim que terminar enviaremos um e-mail para você.',
    buttonLabel: 'OK',
    acao: () => (window.location.href = '#/identidade'),
  },
  cadastroAtualizado: {
    titulo: 'seu cadastro foi atualizado',
    mensagem:
      'Você será direcionado para efetuar o cadastro inicial de suas posições.',
    buttonLabel: 'Acessar',
    acao: () => (window.location.href = '#/identidade'),
  },
};
