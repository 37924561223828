export function loginRequest(email, password) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { email, password },
  };
}

export function loginSucesso(token, usuario) {
  const { user, payment, username } = usuario;

  return {
    type: '@auth/LOGIN_SUCESSO',
    payload: { token, user, payment, username },
  };
}

export function cadastroRequest(nome, email, password) {
  return {
    type: '@auth/CADASTRO_REQUEST',
    payload: { nome, email, password },
  };
}

export function falha(mensagem, numero_tentativas) {
  return {
    type: '@auth/FALHA',
    payload: { mensagem, numero_tentativas },
  };
}

export function logout() {
  return {
    type: '@auth/LOGOUT',
  };
}
