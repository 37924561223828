import { createGlobalStyle } from 'styled-components';

import { devices } from '../utils/functions';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @media ${devices.mobileL} {
    html {
      font-size: 87.5%;
    }
  }
  @media ${devices.mobileM} {
    html {
      font-size: 85%;
    }
  }
  @media ${devices.mobileS} {
    html {
      font-size: 70%;
    }
  }
  @media (max-width: 270px) {
    html {
      font-size: 60%;
    }
  }
`;
