import logo from '../assets/Logotipo_SmarttIR_white.png';

export default function Logo() {
  return (
    <div className="logobussola">
      <a href="https://smarttbot.com/smarttir" target="_blank" rel="noreferrer">
        <img
          src={logo}
          className="logo"
          alt="SmarttIR - A sua calculadora de Imposto de Renda"
        />
      </a>
    </div>
  );
}
