import axios from 'axios';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import alert from '../assets/erro.png';
import InputMaskDemo from '../components/InputMaskDemo';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import { getNumerosCPF } from '../utils/functions';
import {
  validaCPF,
  validaCelular,
  validaNascimento,
} from '../utils/validation';

const ERRO_INICIAL_FORMULARIO = {
  cpf: false,
  nomeCompleto: false,
  nascimento: false,
  celular: false,
};

const MENSAGENS_DE_ERRO = {
  cpf: '',
  nomeCompleto: 'Nome inválido',
  nascimento: 'Data inválida',
  celular: 'Celular inválido',
};

export const Cadastrov2 = () => {
  const usuario = Cookies.get('usuario');
  let cpfToken = '';
  let nome = '';
  let nascimento = '';
  let telefone = '';

  if (usuario) {
    const {
      cpf: _cpfToken,
      _nome,
      _nascimento,
      _telefone,
    } = JSON.parse(usuario);

    cpfToken = _cpfToken;
    nome = _nome;
    nascimento = _nascimento;
    telefone = _telefone;
  }

  const cpf = localStorage.getItem('cpf') || cpfToken;

  const [loading, setLoading] = useState(false);
  const [formulario, setFormulario] = useState({
    cpf,
    nomeCompleto: nome,
    nascimento,
    celular: telefone,
  });
  const [erroFormulario, setErroFormulario] = useState(ERRO_INICIAL_FORMULARIO);
  const [mensagensDeErro, setMensagensDeErro] = useState(MENSAGENS_DE_ERRO);

  async function gravarDados2() {
    let erro = false;

    if (!cpf) {
      if (!validaCPF(formulario.cpf)) {
        setErroFormulario(prevState => ({ ...prevState, cpf: true }));
        setMensagensDeErro({ ...mensagensDeErro, cpf: 'CPF inválido' });
        erro = true;
        return;
      }

      if (formulario.cpf) {
        setLoading(true);
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_AUTH
          }/Auth/check-user-cpf?cpf=${getNumerosCPF(formulario.cpf)}`,
        );

        if (response.data.existe) {
          setErroFormulario(prevState => ({ ...prevState, cpf: true }));
          setMensagensDeErro({
            ...mensagensDeErro,
            cpf: 'CPF já cadastrado.',
          });
          erro = true;
        }
        setLoading(false);
      }
    }

    if (!formulario.nomeCompleto) {
      setErroFormulario(prevState => ({ ...prevState, nomeCompleto: true }));
      erro = true;
    }

    if (!validaNascimento(formulario.nascimento)) {
      setErroFormulario(prevState => ({ ...prevState, nascimento: true }));
      erro = true;
    }

    if (!validaCelular(formulario.celular)) {
      setErroFormulario(prevState => ({ ...prevState, celular: true }));
      erro = true;
    }

    if (erro) return;

    localStorage.setItem('nomeCompleto', formulario.nomeCompleto);
    localStorage.setItem('dtnascimento', formulario.nascimento);
    localStorage.setItem('celular', formulario.celular);
    localStorage.setItem('cpf', formulario.cpf);

    let dataNew = localStorage.getItem('dtnascimento');

    let dataNew2 = dataNew;

    var ano = dataNew2.split('').slice(6, 10).join('');
    var mes = dataNew2.split('').slice(3, 5).join('');
    var dia = dataNew2.split('').slice(0, 2).join('');

    var dataInicioFormatada = ano + '-' + mes + '-' + dia;

    localStorage.setItem('dataInicioFormatada', dataInicioFormatada);

    window.location.href = '#/cadastrov3';
  }

  const handleChange = e => {
    const { id, value } = e.target;

    setFormulario({ ...formulario, [id]: value });
    setErroFormulario({ ...erroFormulario, [id]: false });
  };

  const getErrorClasses = condicao => (condicao ? 'user user_i' : 'user');

  const isThereCpf = !!cpf && cpf !== '0';

  const renderCPF = () => (
    <InputMaskDemo
      id="cpf"
      mask="999.999.999-99"
      placeholder="Digite seu CPF"
      className={getErrorClasses(erroFormulario.cpf)}
      acao={handleChange}
      valor={formulario.cpf}
      disabled={!!isThereCpf}
      autoFocus
    ></InputMaskDemo>
  );

  const renderErro = () => {
    const chaves = Object.keys(mensagensDeErro);

    return chaves.map(chave => {
      if (erroFormulario[chave]) {
        return (
          <small key={chave}>
            <img src={alert} alt="Erro" /> {mensagensDeErro[chave]}
          </small>
        );
      }

      return null;
    });
  };

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              <h2>Estamos quase lá!</h2>
              <p className="f16">Agora, complete seus dados pessoais:</p>

              {renderCPF()}
              <InputText
                id="nomeCompleto"
                placeholder="Digite seu nome completo"
                value={formulario.nomeCompleto}
                onChange={handleChange}
                autoFocus
                className={getErrorClasses(erroFormulario.nomeCompleto)}
              />
              <InputMaskDemo
                id="nascimento"
                mask="99/99/9999"
                placeholder="Digite a sua data de nascimento"
                valor={formulario.nascimento}
                className={getErrorClasses(erroFormulario.nascimento)}
                acao={handleChange}
                autoFocus
              ></InputMaskDemo>
              <InputMaskDemo
                id="celular"
                mask="(99) 99999-9999"
                placeholder="(99) 99999-9999"
                valor={formulario.celular}
                className={getErrorClasses(erroFormulario.celular)}
                acao={handleChange}
                autoFocus
              ></InputMaskDemo>

              <br />
              {renderErro()}

              <Button
                label="Avançar"
                type="submit"
                onClick={gravarDados2}
                loading={+loading}
              />
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
          <br />
        </div>
      </div>
    </div>
  );
};
