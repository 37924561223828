import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Route, useLocation, withRouter } from 'react-router-dom';

import App from './App';
import { Access } from './pages/Access';
import { Cadastro } from './pages/Cadastro';
import { Cadastrov2 } from './pages/Cadastrov2';
import { Cadastrov3 } from './pages/Cadastrov3';
import { CheckEmail } from './pages/CheckEmail/index';
import { Error } from './pages/Error';
import { EsqueciSenha } from './pages/EsqueciSenha';
import { Identidade } from './pages/Identidade';
import { Login } from './pages/Login';
import { Logouf } from './pages/Logouf';
import { NotFound } from './pages/NotFound';
import { Status } from './pages/Status/Status';
import { Token } from './pages/Token';

const AppWrapper = () => {
  let location = useLocation();
  const jsonUsuario = Cookies.get('usuario');

  let usuario = {};

  if (jsonUsuario) {
    usuario = JSON.parse(jsonUsuario);
  }

  const primeiroNome = usuario.nome?.split(' ')[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (location.pathname === '/logouf') {
    return <Route path="/logouf" component={Logouf} />;
  } else {
    switch (location.pathname) {
      case '/':
        return <Route path="/" component={Identidade} />;
      case '/identidade':
        return <Route path="/identidade" component={Identidade} />;
      case '/login':
        return <Route path="/login" component={Login} />;
      case '/cadastro':
        return <Route path="/cadastro" component={Cadastro} />;
      case '/cadastrov2':
        return <Route path="/cadastrov2" component={Cadastrov2} />;
      case '/cadastrov3':
        return <Route path="/cadastrov3" component={Cadastrov3} />;
      case '/token':
        return <Route path="/token" component={Token} />;
      case '/VerifiqueEmail':
        return <Route path="/VerifiqueEmail" component={CheckEmail} />;
      case '/EsqueciSenha':
        return <Route path="/EsqueciSenha" component={EsqueciSenha} />;
      case '/error':
        return <Route path="/error" component={Error} />;
      case '/notfound':
        return <Route path="/notfound" component={NotFound} />;
      case '/access':
        return <Route path="/access" component={Access} />;
      // case "/migracaoIniciada":
      //     return <Route path="/migracaoIniciada" component={Status} usuario={primeiroNome} />;
      case '/migracaoNaoFinalizada':
        return (
          <Route
            path="/migracaoNaoFinalizada"
            component={() => (
              <Status tipo="migracaoNaoFinalizada" usuario={primeiroNome} />
            )}
          />
        );
      case '/cadastroAtualizado':
        return (
          <Route
            path="/cadastroAtualizado"
            component={() => (
              <Status tipo="cadastroAtualizado" usuario={primeiroNome} />
            )}
          />
        );
      // case "/corretoras":
      //     return <Route path="/corretoras" component={() => <Corretoras />} />;
      default: {
        window.location.href = 'https://www.bussoladoinvestidor.com.br/404';
        // return <App />;
      }
    }
  }
};

export default withRouter(AppWrapper);
