import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import alert from '../assets/erro.png';
import olhoAberto from '../assets/ico-olhoaberto.png';
import olhoFechado from '../assets/ico-olhofechado.png';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import { useToast } from '../context/toast/ToastContext';
import { EXPIRED_TOKEN } from '../context/toast/constants';
import api from '../service/api';
import { VALIDA_SENHA_REGEX } from '../utils/constants';

export const EsqueciSenha = props => {
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [olho1, setOlho1] = useState({
    src: olhoFechado,
    type: 'password',
  });
  const [olho2, setOlho2] = useState({
    src: olhoFechado,
    type: 'password',
  });
  const [erro, setErro] = useState(false);
  const [erro2, setErro2] = useState(false);
  const [erro3, setErro3] = useState(false);
  const [erro4, setErro4] = useState(false);
  const [erro5, setErro5] = useState(false);

  const guid = props.location.search.split('guid=')[1];

  const { showToast } = useToast();

  async function handleSenha(campo) {
    if (campo === 1) {
      if (olho1.src === olhoAberto) {
        setOlho1({
          src: olhoFechado,
          type: 'password',
        });
      } else {
        setOlho1({
          src: olhoAberto,
          type: 'text',
        });
      }
    } else if (campo === 2) {
      if (olho2.src === olhoAberto) {
        setOlho2({
          src: olhoFechado,
          type: 'password',
        });
      } else {
        setOlho2({
          src: olhoAberto,
          type: 'text',
        });
      }
    }
  }

  async function handleSubmit() {
    setErro(false);
    setErro2(false);
    setErro3(false);
    setErro4(false);
    setErro5(false);

    if (!password || !confirmpassword) {
      return setErro5(true);
    }

    if (!VALIDA_SENHA_REGEX.test(password)) {
      return setErro4(true);
    }

    if (!VALIDA_SENHA_REGEX.test(confirmpassword)) {
      return setErro4(true);
    }

    if (password !== confirmpassword) {
      return setErro(true);
    }

    try {
      const data = {
        guid,
        password,
        passwordConfirm: confirmpassword,
      };

      await api.post(`/Auth/reset-password`, data);

      window.location.replace(
        `${process.env.REACT_APP_URL_CALCULADORA}/login/#/identidade`,
      );
    } catch (err) {
      showToast(EXPIRED_TOKEN);
    }
  }

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              <h2>Digite uma senha</h2>
              <p className="f16">para acessar a calculadora de IR</p>
              <div>
                <InputText
                  className={erro || erro3 || erro4 ? 'senha senha_i' : 'senha'}
                  name="password"
                  placeholder="Digite sua senha"
                  type={olho1.type}
                  onChange={e => {
                    setPassword(e.target.value);
                    setErro(false);
                    setErro5(false);
                  }}
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSenha(1)}
                  onKeyDown={e => e.code === 'Space' && handleSenha(1)}
                >
                  <img src={olho1.src} alt="olho fechado" />
                </div>
              </div>
              <div>
                <InputText
                  className={erro || erro3 || erro4 ? 'senha senha_i' : 'senha'}
                  name="confirmpassword"
                  placeholder="Confirme sua senha"
                  type={olho2.type}
                  onChange={e => {
                    setConfirmpassword(e.target.value);
                    setErro(false);
                    setErro5(false);
                  }}
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSenha(2)}
                  onKeyDown={e => e.code === 'Space' && handleSenha(2)}
                >
                  <img src={olho2.src} alt="olho aberto" />
                </div>
              </div>
              <h6>* 8 dígitos alfanuméricos e caracteres especiais.</h6>
              {erro2 && <small className="smallB">Aguarde...</small>}
              {erro3 && (
                <small>
                  <img src={alert} alt="Erro" /> Senha incorreta
                </small>
              )}
              {erro5 && (
                <small>
                  <img src={alert} alt="Erro" /> Digite as senhas
                </small>
              )}
              {erro4 && (
                <small>
                  <img src={alert} alt="Erro" /> Insira 8 dígitos alfanuméricos{' '}
                  <br />
                  com caracteres especiais.
                </small>
              )}
              {erro && (
                <small>
                  <img src={alert} alt="Erro" /> As senhas não conferem
                </small>
              )}
              <Button
                label="Entrar"
                type="submit"
                onClick={() => handleSubmit()}
              ></Button>

              <br />
              {/* <p>* A senha deve conter no mínimo 8 dígitos, entre letras, números e caracteres especiais.</p> */}
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
          <br />
        </div>
      </div>
    </div>
  );
};
