import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import alert from '../assets/erro.png';
import olhoAberto from '../assets/ico-olhoaberto.png';
import olhoFechado from '../assets/ico-olhofechado.png';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import api from '../service/api';
import { falha, loginRequest } from '../store/modules/auth/actions';

export const Login = () => {
  const msgErro = useSelector(state => state.auth.mensagemErro);
  const tentatLogin = useSelector(state => state.auth.tentativasLogin);

  const [password, setPassword] = useState();
  const [olho1, setOlho1] = useState({
    src: olhoFechado,
    type: 'password',
  });
  const [tentativas, setTentativas] = useState(0);
  const [mensagem_erro, setMensagem_erro] = useState('');
  const [erro1, setErro1] = useState(false);
  const [erro2, setErro2] = useState(false);
  const [erro3, setErro3] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(false);

  const dispatch = useDispatch();

  const ident = localStorage.getItem('identificacao');
  const Nome = localStorage.getItem('Nome');
  const PrimeiroNome = localStorage.getItem('Nome').split(' ')[0];
  const Id = localStorage.getItem('Id');
  const email = localStorage.getItem('identificacao');

  function handleSubmit() {
    setErro1(false);

    if (!password) {
      return setErro1(true);
    }

    dispatch(loginRequest(ident, password));

    setErro2(true);

    setTentativas(tentatLogin);
    setMensagem_erro(msgErro);

    if (tentativas >= 5) setDisabledLogin(true);

    if (msgErro) {
      setErro2(false);
      setErro3(true);
    }
  }

  async function handleSubmit2() {
    let data = {
      id: Id,
      name: Nome,
      email: email,
    };
    localStorage.setItem('Id', Id);
    await api.post('/Auth/forgot-password', data);
    window.location.href = '#/VerifiqueEmail';
  }

  async function handleSenha() {
    if (olho1.src === olhoAberto) {
      setOlho1({
        src: olhoFechado,
        type: 'password',
      });
    } else {
      setOlho1({
        src: olhoAberto,
        type: 'text',
      });
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  useEffect(() => {
    dispatch(falha('', 0));
    document.title = 'SmarttIR - Login';
  }, []);

  useEffect(() => {
    setTentativas(tentatLogin);
    setMensagem_erro(msgErro);
  }, [msgErro, tentatLogin]);

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              <h2>Olá, {PrimeiroNome}!</h2>
              <p className="f16">Agora informe sua senha.</p>
              <div>
                <InputText
                  className={erro1 || erro3 ? 'senha senha_i' : 'senha'}
                  name="password"
                  type={olho1.type}
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setErro1(false);
                    setErro2(false);
                    setErro3(false);
                  }}
                  autoFocus
                  onKeyDown={handleKeyDown}
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSenha()}
                  onKeyDown={e => e.code === 'Space' && handleSenha()}
                >
                  <img src={olho1.src} alt="olho fechado" />
                </div>
              </div>
              {erro1 && (
                <small>
                  <img src={alert} alt="Erro" /> Preencha a senha
                </small>
              )}
              {erro2 && !mensagem_erro && (
                <small className="smallB">Aguarde...</small>
              )}
              {mensagem_erro && (
                <small>
                  <img src={alert} alt="Erro" /> {mensagem_erro}
                </small>
              )}
              {tentativas === null && (
                <small>
                  <img src={alert} alt="Erro" /> {mensagem_erro}
                </small>
              )}
              {tentativas <= 5 || tentativas !== null ? (
                <Button
                  label="Entrar"
                  type="submit"
                  disabled={disabledLogin}
                  onClick={handleSubmit}
                ></Button>
              ) : (
                <Button label="Entrar" type="submit" disabled></Button>
              )}
              <div
                role="button"
                tabIndex={0}
                onClick={handleSubmit2}
                onKeyDown={e => e.code === 'Enter' && handleSubmit()}
              >
                <a>Esqueci a minha senha</a>
              </div>
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
        </div>
      </div>
    </div>
  );
};
