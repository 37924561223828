import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import AppWrapper from './AppWrapper';
import { ToastProvider } from './context/toast/ToastContext';
import history from './service/history';
import { persistor, store } from './store';
import { GlobalStyle } from './styles/global';
import { defaultTheme } from './styles/themes/default';

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ToastProvider>
        <HashRouter history={history}>
          <ThemeProvider theme={defaultTheme}>
            <AppWrapper />
            <GlobalStyle />
          </ThemeProvider>
        </HashRouter>
      </ToastProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
