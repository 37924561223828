import { ToastInfo } from './types';

export const LIFE = 3000;

export const ERROR_KEY = 'error';
const ERROR_VALUE = {
  severity: 'error',
  summary: 'Ops, algo deu errado.',
  detail: '',
  life: LIFE,
} as ToastInfo;

export const EXPIRED_TOKEN = 'expiredToken';
const EXPIRED_TOKEN_VALUE = {
  ...ERROR_VALUE,
  detail: 'O link usado para cadastrar uma nova senha expirou.',
} as ToastInfo;

const TOAST_DETAILS = new Map([
  [ERROR_KEY, ERROR_VALUE],
  [EXPIRED_TOKEN, EXPIRED_TOKEN_VALUE],
]);

export const getToastInfo = (key: string) => {
  const toastInfo = TOAST_DETAILS.get(key);

  if (toastInfo) return toastInfo;

  return TOAST_DETAILS.get(ERROR_KEY)!;
};
