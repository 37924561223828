import { InputMask } from 'primereact/inputmask';
import React from 'react';

const InputMaskDemo = props => {
  return React.createElement(
    React.Fragment,
    null,
    props.label
      ? React.createElement(
          'span',
          { className: 'p-float-label' },
          React.createElement(InputMask, {
            id: props.id,
            name: props.name,
            mask: props.mask,
            value: props.valor,
            onChange: props.acao,
            onBlur: props.acao2,
            size: props.size,
            autoFocus: props.autofocus,
            disabled: props.disabled,
            className: props.className,
          }),
          React.createElement('label', { htmlFor: props.id }, props.label),
        )
      : React.createElement(InputMask, {
          id: props.id,
          name: props.name,
          mask: props.mask,
          value: props.valor,
          onChange: props.acao,
          onBlur: props.acao2,
          size: props.size,
          placeholder: props.placeholder,
          autoFocus: props.autofocus,
          disabled: props.disabled,
          className: props.className,
        }),
  );
};

export default InputMaskDemo;
