import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

import alert from '../assets/erro.png';
import Logo from '../components/Logo';
import PrecisaDeAjuda from '../components/PrecisaDeAjuda';
import api from '../service/api';

export const Identidade = () => {
  const [email, setEmail] = useState();
  const [erro1, setErro1] = useState(false);
  const [erro2, setErro2] = useState(false);
  const [erro3, setErro3] = useState(false);

  async function handleSubmit() {
    try {
      setErro1(false);
      setErro2(false);
      setErro3(false);

      if (!email) {
        return setErro1(true);
      }

      const emailV = email.split('@')[1];

      if (!emailV) {
        return setErro2(true);
      }

      if (emailV) {
        const emailV2 = email.split('@')[1].split('.')[1];

        if (!emailV2) {
          return setErro3(true);
        }
      }

      const user = await api.get(`/Auth/check-user?Email=${email}`);

      const {
        nome,
        username,
        email: userEmail,
        id,
        existe,
        token,
        redirecionaParceiro,
        semSenha,
      } = user.data;

      localStorage.setItem('identificacao', userEmail);
      localStorage.setItem('token', token);
      localStorage.setItem('Nome', nome);
      localStorage.setItem('Id', id);
      localStorage.setItem('username', username);
      localStorage.setItem('redirecionaParceiro', redirecionaParceiro);
      localStorage.setItem('semSenha', semSenha);

      if (!user || !existe) {
        window.location.href = '#/token';
        return;
      }

      if (redirecionaParceiro && semSenha) {
        window.location.href = '#/VerifiqueEmail';
        return;
      }

      window.location.href = '#/login';
    } catch (error) {}
  }

  function limparDados() {
    localStorage.clear();
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  useEffect(() => {
    limparDados();
    localStorage.setItem('identificacao', '');
    localStorage.setItem('Nome', '');
    document.title = 'SmarttIR - Login';
  }, []);

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <div>
            <Logo />

            <div className="login-form">
              <div>
                <h2>Informe seu e-mail</h2>
                <p className="f400 f16">
                  para acessar a Calculadora de IR ou cadastrar grátis
                </p>
              </div>
              <InputText
                className={erro1 || erro2 || erro3 ? 'email email_i' : 'email'}
                name="email"
                type="email"
                placeholder="Digite seu e-mail"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setErro1(false);
                  setErro2(false);
                  setErro3(false);
                }}
                autoFocus
                onKeyDown={handleKeyDown}
              />
              {erro1 && (
                <small>
                  <img src={alert} alt="Erro" /> Digite seu e-mail
                </small>
              )}
              {erro2 && (
                <small>
                  <img src={alert} alt="Erro" /> Seu e-mail está incompleto
                </small>
              )}
              {erro3 && (
                <small>
                  <img src={alert} alt="Erro" /> Seu e-mail está incompleto
                </small>
              )}
              <Button
                label="Avançar"
                type="submit"
                onClick={handleSubmit}
              ></Button>
            </div>

            <div className="login-footer pLink">
              <p>Primeira vez aqui?</p>

              <div>
                <a
                  href="https://smarttbot.com/smarttir"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conheça a SmarttIR
                </a>
                <a
                  href="https://smarttbot.com/planos-smarttir/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conheça nossos planos
                </a>
              </div>
            </div>
          </div>
          <small className="pLink">
            <PrecisaDeAjuda />
          </small>
        </div>
      </div>
    </div>
  );
};
