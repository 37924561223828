import { InputText } from 'primereact/inputtext';
import React from 'react';

const InputTextDemo = props => {
  return React.createElement(
    React.Fragment,
    null,
    props.label
      ? React.createElement(
          'span',
          { className: 'p-float-label' },
          React.createElement(InputText, {
            type: props.type,
            id: props.id,
            name: props.name,
            value: props.valor,
            onChange: props.acao,
            size: props.size,
            disabled: props.disabled,
            autoFocus: props.autofocus,
            max: props.max,
            className: props.className,
          }),
          React.createElement('label', { htmlFor: 'inputtext' }, props.label),
        )
      : React.createElement(InputText, {
          type: props.type,
          id: props.id,
          name: props.name,
          value: props.valor,
          onChange: props.acao,
          size: props.size,
          placeholder: props.placeholder,
          disabled: props.disabled,
          autoFocus: props.autofocus,
          max: props.max,
          className: props.className,
        }),
  );
};

export default InputTextDemo;
